import React, { useState, useEffect, useRef } from 'react';
import CustomCursor from "./components/CustomCursor";

function Home() {
	const castmates = [
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/pork.webp', alt: 'Pork pfp', bio: 'Nickle\'s best pal. Smells smoky', name: 'Pork' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/nickle.webp', alt: 'Nickle pfp', bio: 'Pork\'s best pal. Has no cash value.', name: 'Nickle' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/gromblar.webp', alt: 'Gromblar pfp', bio: 'Your favorite Ogre Influencer', name: 'Gromblar' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/dimo.webp', alt: 'Dimo pfp', bio: 'Spasetronaut, Explorer, Janitorial Intern', name: 'Dimo' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/remy.webp', alt: 'Remy pfp', bio: 'Karaoke King', name: 'Remy' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hargablat.webp', alt: 'Hargablat pfp', bio: 'Bike Gang leader by night, sleeping by day.', name: 'Hargablat' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/gregoor.webp', alt: 'Gregoor pfp', bio: 'He\'s thinkin!', name: 'Gregoor' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/fobo.webp', alt: 'Fobo pfp', bio: 'PISS Researches number one biologist', name: 'Fobo' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/manny.webp', alt: 'Manny pfp', bio: 'Trendsetter', name: 'Manny' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/peddy.webp', alt: 'Peddy pfp', bio: 'Feet are his passion', name: 'Peddy' },
		// { src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/hihi.webp', alt: 'The Announcer pfp', bio: 'You\'ve heard his voice in your dreams', name: 'The Announcer' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/grobler.webp', alt: 'Doktur Grobler pfp', bio: 'Don\'t forget to take your brane pills!', name: 'Doktur Grobler' },
		{ src: 'https://truth-public-content.s3.us-east-2.amazonaws.com/garf.webp', alt: 'Garf pfp', bio: 'Anxiety adds flavor!', name: 'Garf' },
	];

	const episodes = [
		{ title: 'Soda Stream', release: '3.18', description: 'Hargablat does NOT work at the gas station.', url: 'https://www.tiktok.com/t/ZTLYaJm9X/', isLive: true },
		{ title: 'Pro Instant Sandwich Slicer 5000', release: '3.25', description: 'The only Sandwich Slicer you\'ll ever need!', url: 'https://www.tiktok.com/@goblintown/video/7350332493670878510', isLive: true },
		{ title: 'Garf Snarfs Pickles n Milk', release: '4.1', description: 'Join Garf for another Snarf as he makes his favorite cozy meal: Pickles and Milk!', url: 'https://www.tiktok.com/@goblintown/video/7352937734308171050', isLive: true },
		{ title: 'Fone a Fwend', release: '4.8', description: 'Pork and Nickle play with their new phones.', url: 'https://www.tiktok.com/@goblintown/video/7355557424872312106', isLive: true },
		{ title: 'Gromblar Glogs - Mud Mask from a Costco Dumpster', release: '4.15', description: 'Gromblar shares his newest beauty review: Mud Mask From a Costco Dumpster.', url: 'https://www.tiktok.com/@goblintown/video/7358144920181542190', isLive: true },
		{ title: 'Garf Snarfs Hot Dogs', release: '4.22', description: 'Join Garf for another Snarf as he makes one of his favorite snacks: Hot Dogs!', url: 'https://www.tiktok.com/t/ZTLaQ8GJx/', isLive: true },
		{ title: 'Like a Glove', release: '4.29', description: 'Gobstronauts suit up for a hard day of Space Work.', url: 'https://www.tiktok.com/t/ZTL9dP5VH/', isLive: true },
		{ title: 'Garf Snarfs a Smoothie', release: '5.6', description: 'Join Garf for another Snarf as he makes a healthy meal alternative: Smoothies!', url: 'https://www.tiktok.com/t/ZTLCwGPGU/', isLive: true },
		{ title: 'Red Pigeon', release: '5.13', description: 'I love this song!', url: 'https://www.tiktok.com/t/ZTLqfM4Y5/', isLive: true },
		{ title: 'Garf Snarfs a Sandwich', release: '5.20', description: 'Join Garf for another Snarf as he makes a quick snack: Sandwiches!', url: 'https://www.tiktok.com/t/ZPRKRjs1p/', isLive: true },
		{ title: 'The Noes Knows', release: '5.27', description: 'Gregor is thinking!', url: 'https://www.tiktok.com/t/ZTLW66tUs/', isLive: true },
		{ title: 'Wood Soop', release: '6.3', description: 'Relax and kick back to the soothing sounds of a delicious wood soup.', url: 'https://www.tiktok.com/t/ZPRKHU3L4/', isLive: true },
		{ title: 'Manny & Peddy: A Love Story', release: '6.10', description: 'Pedicures are on sale!', url: 'https://www.tiktok.com/t/ZPRKse1k7/', isLive: true },
		{ title: 'Garfs Snarfs a Tidy Workspace', release: '6.17', description: 'Join Garf for another Snarf as he cleans up his work station!', url: 'https://www.tiktok.com/t/ZPRE6eDmh/', isLive: true },
	];

	const [isActive, setIsActive] = useState(false);
	const audioRef = useRef(new Audio('https://truth-public-content.s3.us-east-2.amazonaws.com/theme.mp3'));

	useEffect(() => {
		const audio = audioRef.current;
		audio.loop = true;
	
		const toggleButton = document.querySelector('.audioToggle');
		let touchEndTime = 0;
	
		const togglePlayPause = () => {
			if (audio.paused) {
				audio.play();
				setIsActive(true);
			} else {
				audio.pause();
				setIsActive(false);
			}
		};
	
		const handleTouchEnd = (event) => {
			touchEndTime = Date.now();
			event.preventDefault();
			togglePlayPause();
		};
	
		const handleClick = (event) => {
			if (Date.now() - touchEndTime > 500) {
				event.preventDefault();
				togglePlayPause();
			}
		};
	
		toggleButton.addEventListener('touchend', handleTouchEnd, { passive: false });
		toggleButton.addEventListener('click', handleClick, { passive: false });
	
		return () => {
			audio.pause();
			toggleButton.removeEventListener('touchend', handleTouchEnd);
			toggleButton.removeEventListener('click', handleClick);
		};
	}, []);

	useEffect(() => {
		const beepBoopButtons = document.querySelectorAll('.beepBoop');
		let touchEndTime = 0;

		const handleAction = (event) => {
			event.preventDefault();
			window.open(event.currentTarget.href, '_blank');
		};

		const handleTouchEnd = (event) => {
			touchEndTime = Date.now();
			handleAction(event);
		};

		const handleClick = (event) => {
			if (Date.now() - touchEndTime > 500) {
				handleAction(event);
			}
		};

		beepBoopButtons.forEach(button => {
			button.addEventListener('touchend', handleTouchEnd, { passive: false });
			button.addEventListener('click', handleClick, { passive: false });
		});

		return () => {
			beepBoopButtons.forEach(button => {
				button.removeEventListener('touchend', handleTouchEnd);
				button.removeEventListener('click', handleClick);
			});
		};
	}, []);


	return (
		<div id="spitSpots" className='lander'>
			<CustomCursor />
			<div id="hero" className="edge">
				{/* masthead */}
				<header id="head">
					<img id="beet" src="i/beet.png" alt="" />
					<div id="vandalism" className="target target--gob">
						<h1 id="truthLogo" className="target target--gob">
							<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth FArts Animated Mark" title="Truth FArts Mark" />
							<span className="logoType">Truth <span className="gobfiti">F</span>Arts</span>
						</h1>
					</div>
					<img id="wheatPaste" src="i/logos/gobs-logo-sticker.png" alt="Goblintown" title="Goblintown" />

					{/* main navigation */}
					<nav id="bigNav">
						<a className="navItem target target--gob beepBoop" href="https://trutharts.com" title="Back to the Truth FArts Homepage" target="_blank" rel="noreferrer">Home</a>
						<a className="navItem target target--gob" href="#episodes" title="View the Episode List">Episodes</a>
						<a className="navItem target target--gob" href="#cast" title="Meet our Cast">Cast</a>
						{/* <a className="navItem target target--gob" href="https://blog.trutharts.com" target="_blank" rel="noreferrer" title="Visit the Truth Arts Blog">Troof Blog</a> */}
						<div className="btnGroup">
							<div
								id="soundToggle"
								className={`button target target--gob inverse ${isActive ? 'active' : ''} audioToggle`}
								style={{ pointerEvents: 'auto', zIndex: 1000 }}
							>
								{isActive ? <img src="i/pause.png" alt="Pause" /> : <img src="i/play.png" alt="Play" />}
							</div>
							<a className="button target target--gob beepBoop" href="https://www.tiktok.com/@goblintown" target="_blank" rel="noreferrer" title="Follow Goblintown on Tiktok">TuNE In</a>
						</div>
					</nav>
				</header>
				{/* hero art */}
				<div className="art">
					<div id="heroScreen">
						<video autoPlay loop muted poster="v/poster.png">
							<source src="https://truth-public-content.s3.us-east-2.amazonaws.com/cut-chunky.mp4" type="video/mp4" />
						</video>
					</div>
					<div id="heroSet" className="layer">
						<img src="https://truth-public-content.s3.us-east-2.amazonaws.com/hero-set.webp" alt="" />
					</div>
					<div id="heroLight" className="layer">
						<img src="https://truth-public-content.s3.us-east-2.amazonaws.com/hero-light.webp" alt="" />
					</div>
					<div id="heroSkele" className="layer">
						<img src="i/hero/hero-skele.png" alt="" />
					</div>
					<div id="heroShock" className="layer">
						<img src="i/hero/hero-shock.png" alt="" />
					</div>
				</div>
				{/* burn hero copy */}
				<div className="copy">
					<h2 className="screamer">
						Bite&ndash;<br></br>
						SIzEd<br></br>
						brain<br></br>
						&ndash;Rot.
					</h2>
				</div>
			</div>
			<main className="site">
				{/* intro */}
				<section id="intro">
					{/* <div id="spitLogo" >
						<img src="i/logos/spit-tv-title.png" alt="Goblintown's Spit Spots" title="Goblintown's Spit Spots Title Card" />
					</div> */}
					<div className="copy">
						{/* <h3 className="screamer">Squish&hellip;</h3> */}
						<h3>Crawl inside the sticky brain cavity of a goblin and chew on the bite-sized anthology show made just for them.</h3>
						{/* <a className="button--big target target--gob" href="#episodes" title="View the Episode List">View Episodes</a> */}
					</div>
					{/* spit social */}
					<div id="spitSocial" className="grid">
						<a href="https://www.tiktok.com/@goblintown" className="socialCard edge target target--gob beepBoop" target="_blank" rel="noreferrer" title="Follow Goblintown on Tiktok">
							<img className="stupidSquare" src="i/stupid-square.png" alt="" />
							<div className="splat">
								<img src="i/tik-tok-splat.png" alt="Goblintown on TikTok" title="TikTok Logo Splat" />
							</div>
						</a>
						<a href="https://www.instagram.com/goblintownofficial/" className="socialCard edge target target--gob beepBoop" target="_blank" rel="noreferrer" title="Follow Goblintown on Instagram">
							<img className="stupidSquare" src="i/stupid-square.png" alt="" />
							<div className="splat">
								<img src="i/instagram-splat.png" alt="Goblintown on Instagram" title="TikTok Logo Splat" />
							</div>
						</a>
						<a href="https://www.youtube.com/@goblintownofficial" className="socialCard edge target target--gob beepBoop" target="_blank" rel="noreferrer" title="Follow Goblintown on Youtube">
							<img className="stupidSquare" src="i/stupid-square.png" alt="" />
							<div className="splat">
								<img src="i/youtube-splat.png" alt="Goblintown on Youtube" title="Youtube Logo Splat" />
							</div>
						</a>
					</div>
				</section>

				{/* season 1 poster */}
				<img id="s1Poster" src="i/s1-poster.jpg" alt="Spit Spots Season 1 Poster" title="Spit Spots Season 1 Poster" />

				{/* episodes */}
				<section id="episodes">
					<nav id="seasonNav">
						<h3 className="tab active">Season 1</h3>
						<h3 className="tab">Season 2</h3>
					</nav>
					<h2 className="screamer">Episodes</h2>
					<div className="wiggleDivide">
						<img src="i/wiggle-line.png" alt="" loading="lazy" />
					</div>
					<div id="episodesList">
						{episodes.map((episode, index) => (
							<div className={`episode wigglySides ${episode.isLive ? 'live' : ''}`} key={index}>
								<div className="episodeInfo">
									<div className="episodeMeta">
										<h3 className="episodeNumber">s1e{index + 1}:</h3>
										<h3 className="episodeTitle">{episode.title}</h3>
										<h3 className="episodeRelease">{episode.release}.24</h3>
									</div>
									<p className="episodeDescription">{episode.description}
									</p>
								</div>
								<a className={`button button--big target target--gob beepBoop ${episode.isLive ? 'live' : ''}`} href={episode.url} target="_blank" rel="noreferrer" title={`Watch Spit Spts S1E${index + 1} on TikTok`}>Watch Now</a>
								<button className="button button--big unreleased" title={`Spit Spts S1E${index + 1} coming ${episode.release} on TikTok`}>Coming {episode.release}</button>
							</div>
						))}
					</div>
				</section>
				{/* cast */}
				<section id="cast">
					<h2 className="screamer">Cast</h2>
					<div className="wiggleDivide">
						<img src="i/wiggle-line.png" alt="" loading="lazy" />
					</div>
					<div id="castGrid" className="grid grid-4">
						{castmates.map((castmate, index) => (
							<div key={index} className="pfp castmate">
								<div className="castmatePfp edge">
									<img src={castmate.src} alt={castmate.alt} title="{castmate.alt}" loading="lazy" />
								</div>
								<div className="castmateAbout">
									<h3 className="castmateName">{castmate.name}</h3>
									<p className="castmateBio">{castmate.bio}</p>
								</div>
							</div>
						))}
					</div>
				</section>

			</main>
			<footer id="foot">
				<div id="contact" className="wigglySides hasToggle ">
					<a href="https://www.tiktok.com/@goblintown" target="_blank" rel="noreferrer" title="Follow Goblintown on Tiktok" className="beepBoop"><h3 className="screamer target target--gob">Watch NOw on TiktOK!</h3></a>
					<i className="toggle"></i>
				</div>
				<div className="wiggleDivide">
					<img src="i/wiggle-line.png" alt="" loading="lazy" />
				</div>
				<div id="footContent">
					<div id="footAbout">
						<a href="https://www.trutharts.com/" id="footLogo" className="target target--brand beepBoop" target="_blank" rel="noreferrer" >
							<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth Arts Animated Mark" title="Truth Arts Mark" />
						</a>
						<p>Truth FArts izza clective o <strong>mighty sooper cree-aters</strong> makin' <strong>whirlds an storees</strong> wit lotsa <strong>heartpokin' sillies</strong> bout <strong>goblins</strong> an <strong>draggins</strong> an <strong>ogurs</strong> an <strong>majik</strong> an <strong>buttbats</strong> an <strong>aleeins</strong> an <strong>monsturs</strong> an tings like dat.</p>
					</div>
					<div id="footSocial" className="edge">
						<a id="xLink" href="https://twitter.com/goblintown" target="_blank" rel="noreferrer" className="socialLink target target--gob edge beepBoop" title="Follow Goblintown on X">
							<i className='socialIcon'></i>
							<h5>Follow on X</h5>
						</a>
						{/* <a id="discordLink" href="https://discord.gg/illuminati" target="_blank" rel="noreferrer" className="socialLink target target--gob edge" title="Join the Truth FArts Discord">
							<i className='socialIcon'></i>
							<h5>JOYN DA DISCROD</h5>
						</a> */}
						<a id="shopLink" href="https://shop.goblintown.com/" target="_blank" rel="noreferrer" className="socialLink target target--gob edge beepBoop" title="Shop Goblintown Merch">
							<i className='socialIcon'></i>
							<h5>Shop Merch</h5>
						</a>
						{/* <a id="magicEdenLink" href="https://discord.gg/illuminati" target="_blank" rel="noreferrer" className="socialLink target target--gob edge" title="Buy gobs on MagicEden">
							<i className='socialIcon'></i>
							<h5>Buy on Magic Eden</h5>
						</a> */}
					</div>
				</div>
				<div className="wiggleDivide">
					<img src="i/wiggle-line.png" alt="" loading="lazy" />
				</div>
				<div id="footFoot">
					<div id="colo">&copy;2024 Truth Arts Inc. All Rights Reserved.</div>
					<div id="legal">
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Privacy+Policy_2.21.24.pdf" className="target target--gob beepBoop" target="_blank" rel="noreferrer" title="Read the Truth Arts Privacy Policy">Privacy Policy</a>
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Truth+Arts_Terms+and+Conditions.pdf" className="target target--gob beepBoop" target="_blank" rel="noreferrer" title="Read the Truth Arts Terms and Conditions">Terms and Conditions</a>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Home;
